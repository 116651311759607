<template>
    <div v-if="compareList.length > 0 && compareActive" class="compare-list">
        <div class="swiper-container">
            <Swiper
                :dir="rtl"
                :autoplay="{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }"
                :pagination="{ clickable: true }"
                :modules="[SwiperPagination, SwiperAutoplay]"
                :parallax="true"
                pagination
                slides-per-view="1.5"
                spaceBetween="20"
                :breakpoints="{
                    '425': {
                        slidesPerView: 2,
                    },
                    '576': {
                        slidesPerView: 2.5,
                    },
                    '768': {
                        slidesPerView: 3,
                    },
                }"
            >
                <swiper-slide v-for="course in compareList" :key="course.id">
                    <div class="compare-list-item">
                        <i
                            class="pi pi-times"
                            style="cursor: pointer; align-self: flex-end"
                            @click="removeFromCompareList(course.id)"
                        ></i>

                        <img class="course-icon" src="../../../assets/images/search/course_cap.svg" alt="Course Icon" />

                        <div class="course-name">{{ course.name }}</div>

                        <div class="course-location">
                            <img alt="location.svg" height="26" src="@/assets/img/location.svg" width="10" />
                            <span>{{ course.location }}</span>
                        </div>
                    </div>
                </swiper-slide>
            </Swiper>
        </div>

        <Button
            :label="t('Startyoucomparison')"
            :disabled="compareList.length < 2"
            @click="startComparison"
            size="small"
        ></Button>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useCommonStore } from '../../../store/search/common';

const { t, locale } = useI18n();
const router = useRouter();

const commonStore = useCommonStore();
const { compareList, compareActive } = storeToRefs(commonStore);

const rtl = computed(() => locale.value === 'ar');

const removeFromCompareList = id => {
    compareList.value = compareList.value.filter(item => item.id !== id);
};

const startComparison = () => {
    router.push('/compare');
};
</script>

<style lang="scss" scoped>
.compare-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    background-color: #edeaf8;
    width: 100vw;
    padding: 0.5rem;

    @media (min-width: 1024px) {
        padding: 1rem;
    }

    .swiper-container {
        width: 100%;
        margin-bottom: 2rem;

        @media (min-width: 1024px) {
            width: 70%;
        }

        @media (min-width: 1200px) {
            width: 50%;
        }
    }

    .compare-list-item {
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
        background-color: #fff;
        border-radius: 0.5rem;
        border: 1px solid #f0f0f0;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        gap: 0.5rem;

        .course-icon {
            align-self: center;
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            background-color: #fff;
            z-index: 1;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
            border: 1px solid #f0f0f0;
            padding: 0.5rem;
        }

        .course-name {
            align-self: center;
            text-align: center;
            font-size: 1rem;
            font-weight: 500;
            color: #222;
            font-family: 'Oswald';
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .course-location {
            align-self: center;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-size: 0.8rem;
            color: #222;
            font-weight: 600;
            font-family: 'Mulish';
        }
    }
}
</style>
