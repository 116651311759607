<template>
    <div class="compare-container" @click="compareActive = !compareActive">
        <i class="icomoon icon-compare"></i>
        <span>{{ compareText }}</span>
    </div>
</template>

<script setup>
import { useCommonStore } from '../../../store/search/common';

const commonStore = useCommonStore();
const { compareActive } = storeToRefs(commonStore);

const { t } = useI18n();

const compareText = computed(() => {
    return compareActive.value ? t('Cancel') : t('advSearch.compare');
});
</script>

<style lang="scss" scoped>
@import 'libs/shared/assets/scss/main.scss';

.compare-container {
    align-items: center;
    gap: toRem(12);
    padding: toRem(12);
    border-radius: toRem(10);
    border: 1px solid $primary-color;
    display: inline-flex;
    justify-content: center;
    cursor: pointer;
    width: toRem(230);
    height: 64px;
    margin: 0 auto;
    i {
        color: $primary-color;
        font-size: toRem(25);
    }
    span {
        color: $primary-color;
        font-size: toRem(16);
        font-weight: 800;
    }
}
@include mediaMin($lg) {
    .compare-container {
        width: auto;
        margin: 0;
        i {
            font-size: toRem(34);
        }
        span {
            font-size: toRem(18);
        }
    }
}
</style>
